<template>
    <div class="card-compra p-2 bg-white">
        <div class="row mx-0 a-center">
            <div class="col-auto px-2 f-15">
                0 / 100
            </div>
        </div>
        <div class="row mx-0">
            <div class="mn-pill bg-fondo f-600 mr-3">
                <img src="/img/gaming/i_moneda.svg" height="20" />
                <span class="mx-2">+2</span>
            </div>
            <div class="mn-pill bg-fondo f-600">
                <img src="/img/gaming/i_gema.svg" height="20" />
                <span class="mx-2">+1</span>
            </div>
        </div>
        <div class="row mx-0 mt-2 br-20 bg-fondo2" style="height:5px;">
            <div class="h-100 br-20 bg-green" :style="`width:${porcentaje}%`" />
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            porcentaje: 50
        }
    }
}
</script>

<style lang="scss" scoped>
.card-compra{
    //width: 285px;
    width: 100%;
    min-height: 72px;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 8px;
}
.bg-fondo2{
    background-color: #DFE4E8;
}
</style>